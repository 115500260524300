import dynamic from 'next/dynamic'

export const DialogObserver = dynamic(() => import('./dialog'), { ssr: false })
export const LoaderObserver = dynamic(() => import('./loader'), { ssr: false })
export const NoticeObserver = dynamic(() => import('./notice'), { ssr: false })
export const ToastObserver = dynamic(() => import('./toast'), { ssr: false })

export type * from './dialog/dialog.type'
export type * from './notice/notice.type'
export type * from './toast/toast.type'
