import(/* webpackMode: "eager" */ "D:\\xampp\\htdocs\\oasis_website\\node_modules\\@next\\third-parties\\dist\\google\\ga.js");
;
import(/* webpackMode: "eager" */ "D:\\xampp\\htdocs\\oasis_website\\node_modules\\@next\\third-parties\\dist\\google\\gtm.js");
;
import(/* webpackMode: "eager" */ "D:\\xampp\\htdocs\\oasis_website\\node_modules\\@next\\third-parties\\dist\\ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "D:\\xampp\\htdocs\\oasis_website\\node_modules\\next-intl\\dist\\esm\\navigation\\shared\\BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "D:\\xampp\\htdocs\\oasis_website\\node_modules\\next-intl\\dist\\esm\\navigation\\shared\\LegacyBaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "D:\\xampp\\htdocs\\oasis_website\\node_modules\\next-intl\\dist\\esm\\shared\\NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "D:\\xampp\\htdocs\\oasis_website\\node_modules\\next\\dist\\client\\script.js");
;
import(/* webpackMode: "eager" */ "D:\\xampp\\htdocs\\oasis_website\\node_modules\\next\\font\\google\\target.css?{\"path\":\"src\\\\constants\\\\fonts\\\\index.ts\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-inter\"}],\"variableName\":\"fontInter\"}");
;
import(/* webpackMode: "eager" */ "D:\\xampp\\htdocs\\oasis_website\\node_modules\\next\\font\\google\\target.css?{\"path\":\"src\\\\constants\\\\fonts\\\\index.ts\",\"import\":\"Noto_Sans_Thai\",\"arguments\":[{\"subsets\":[\"latin\",\"thai\"],\"display\":\"swap\",\"variable\":\"--font-thai\"}],\"variableName\":\"fontThai\"}");
;
import(/* webpackMode: "eager" */ "D:\\xampp\\htdocs\\oasis_website\\node_modules\\next\\font\\local\\target.css?{\"path\":\"src\\\\constants\\\\fonts\\\\index.ts\",\"import\":\"\",\"arguments\":[{\"preload\":true,\"display\":\"swap\",\"variable\":\"--font-acumin\",\"src\":[{\"path\":\"./acumin-pro/Acumin_Pro_Regular.woff\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"./acumin-pro/Acumin_Pro_Italic.woff\",\"weight\":\"400\",\"style\":\"italic\"},{\"path\":\"./acumin-pro/Acumin_Pro_Bold.woff\",\"weight\":\"700\",\"style\":\"normal\"},{\"path\":\"./acumin-pro/Acumin_Pro_Bold_Italic.woff\",\"weight\":\"700\",\"style\":\"italic\"}]}],\"variableName\":\"fontAcumin\"}");
;
import(/* webpackMode: "eager" */ "D:\\xampp\\htdocs\\oasis_website\\node_modules\\next\\font\\local\\target.css?{\"path\":\"src\\\\constants\\\\fonts\\\\index.ts\",\"import\":\"\",\"arguments\":[{\"preload\":true,\"display\":\"swap\",\"variable\":\"--font-butler\",\"src\":[{\"path\":\"./butler/Butler_Regular.woff\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"./butler/Butler_Ultra_Light.woff\",\"weight\":\"200\",\"style\":\"normal\"},{\"path\":\"./butler/Butler_Light.woff\",\"weight\":\"300\",\"style\":\"normal\"},{\"path\":\"./butler/Butler_Medium.woff\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"./butler/Butler_Bold.woff\",\"weight\":\"700\",\"style\":\"normal\"},{\"path\":\"./butler/Butler_ExtraBold.woff\",\"weight\":\"800\",\"style\":\"normal\"},{\"path\":\"./butler/Butler_Black.woff\",\"weight\":\"900\",\"style\":\"normal\"}]}],\"variableName\":\"fontButler\"}");
;
import(/* webpackMode: "eager" */ "D:\\xampp\\htdocs\\oasis_website\\node_modules\\next\\font\\local\\target.css?{\"path\":\"src\\\\constants\\\\fonts\\\\index.ts\",\"import\":\"\",\"arguments\":[{\"preload\":true,\"display\":\"swap\",\"variable\":\"--font-myriad\",\"src\":[{\"path\":\"./myriad-pro/Myriad_Pro_Regular.woff\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"./myriad-pro/Myriad_Pro_Light.woff\",\"weight\":\"100\",\"style\":\"normal\"},{\"path\":\"./myriad-pro/Myriad_Pro_Semibold.woff\",\"weight\":\"700\",\"style\":\"normal\"},{\"path\":\"./myriad-pro/Myriad_Pro_Semibold_Italic.woff\",\"weight\":\"700\",\"style\":\"italic\"},{\"path\":\"./myriad-pro/Myriad_Pro_Bold.woff\",\"weight\":\"700\",\"style\":\"normal\"},{\"path\":\"./myriad-pro/Myriad_Pro_Bold_Italic.woff\",\"weight\":\"700\",\"style\":\"italic\"}]}],\"variableName\":\"fontMyriad\"}");
;
import(/* webpackMode: "eager" */ "D:\\xampp\\htdocs\\oasis_website\\node_modules\\next\\font\\local\\target.css?{\"path\":\"src\\\\constants\\\\fonts\\\\index.ts\",\"import\":\"\",\"arguments\":[{\"preload\":true,\"display\":\"swap\",\"variable\":\"--font-myriad-condensed\",\"src\":[{\"path\":\"./myriad-pro/Myriad_Pro_Condensed.woff\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"./myriad-pro/Myriad_Pro_Condensed_Italic.woff\",\"weight\":\"400\",\"style\":\"italic\"},{\"path\":\"./myriad-pro/Myriad_Pro_Bold_Condensed.woff\",\"weight\":\"700\",\"style\":\"normal\"},{\"path\":\"./myriad-pro/Myriad_Pro_Bold_Condensed_Italic.woff\",\"weight\":\"700\",\"style\":\"italic\"}]}],\"variableName\":\"fontMyriadCondensed\"}");
;
import(/* webpackMode: "eager", webpackExports: ["AppProvider"] */ "D:\\xampp\\htdocs\\oasis_website\\src\\components\\provider.tsx");
;
import(/* webpackMode: "eager" */ "D:\\xampp\\htdocs\\oasis_website\\src\\styles\\globals.scss");
