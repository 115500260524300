'use client'

import { NextUIProvider } from '@nextui-org/system'
import { ThemeProvider as NextThemesProvider } from 'next-themes'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/navigation'

import type { ReactProps } from '@/types'

import { DialogObserver, LoaderObserver, NoticeObserver, ToastObserver } from './addons'

const BootstrapObserver = dynamic(() => import('./bootstrap'), { ssr: false })

export function AppProvider({ children }: ReactProps) {
  // __STATE's
  const router = useRouter()

  // __RENDER
  return (
    <NextUIProvider navigate={router.push}>
      <NextThemesProvider attribute='class' defaultTheme='light'>
        {children}
        <BootstrapObserver />
        <LoaderObserver />
        <DialogObserver />
        <NoticeObserver />
        <ToastObserver />
      </NextThemesProvider>
    </NextUIProvider>
  )
}
